@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    user-select: none;
}

.bgIMG {
    background-image: url("https://images.prismic.io/skullcandy/a5890f4d-f24d-4223-a960-6066e251d197_Image20220922111750.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.InsideSckullcandy {
    background-image: url("https://images.prismic.io/skullcandy/2830ea72-cf38-41c0-87cb-2381bdd27474_1b_slider_desktop_unleashthevisceral.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgAdverstingIMG {
    background-image: url("https://images.prismic.io/skullcandy/c8feb1ff-73b1-4c0c-9414-81b68d84af0a_P7_desktop_skulliq.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgHoverIMG {
    background-image: url("https://images.prismic.io/skullcandy/0b48017b-f009-4389-a278-f56a103114e5_4_desktop_ourculture.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgHoverIMG2 {
    background-image: url("https://images.prismic.io/skullcandy/29c34664-3f8d-4859-b3a4-95858966b2dc_3_desktop.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgHoverIMG3 {
    background-image: url("https://images.prismic.io/skullcandy/2d6397d1-2cc2-4c85-a939-87e03e8dfd44_5_desktop.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgHoverIMG4 {
    background-image: url("https://images.prismic.io/skullcandy/ce3fa05a-972c-433c-9224-ded34638d087_FYF+-+Desktop+blade.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgAdversting2IMG {
    background-image: url("https://images.prismic.io/skullcandy/67fcb034-408c-4f58-923a-4c4e0f203f0c_P7_desktop_gaming.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgIMGfirst {
    background-image: url("https://images.prismic.io/skullcandy/35db5ddd-2c84-4cb0-85fc-a23bea09e17c_2_gaming.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgIMGsecond {
    background-image: url("https://images.prismic.io/skullcandy/bf9c44c1-bf88-460b-adac-c29f589c618d_3_indyevo.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgIMGhoverIMG {
    background-image: url("https://images.prismic.io/skullcandy/11df2eb3-abfd-4e65-b9fd-2d587e9a6b62_4_desktop_dime2.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
    transition: .3s;
}

.bgIMGhoverIMG2 {
    background-image: url("https://images.prismic.io/skullcandy/c7e288b4-17d2-426e-871f-83eeda6fadbf_4_desktop_mod+%281%29.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
    transition: .3s;
}

.bgIMGhoverIMG3 {
    background-image: url("https://images.prismic.io/skullcandy/1958dd82-bb33-4ae7-81bd-a382605e6e3e_4_desktop_pushactive.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
    transition: .3s;
}

.bgIMGhoverIMG:hover {
    background-image: url("https://images.prismic.io/skullcandy/dfc7546e-d47b-4577-8759-69cd2610a910_4_desktop_dime2_model.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgIMGhoverIMG2:hover {
    background-image: url("https://images.prismic.io/skullcandy/baf43e99-5531-4c06-ac6d-6f5d9c7a2db9_4_desktop_mod_model+%281%29.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

.bgIMGhoverIMG3:hover {
    background-image: url("https://images.prismic.io/skullcandy/5dc3d207-f42b-42a5-b20c-d2048352c96a_4_desktop_pushactive_model.jpg?auto=compress,format");
    background-position: center;
    background-size: cover;
}

::-webkit-scrollbar {
    background-color: rgb(0, 0, 0);
    width: 15px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(73, 73, 73);
    border-radius: 5px;
}